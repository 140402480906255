import { JsConfig } from "./types";

//var apiBase = process.env.REACT_APP_apiBase + '/W_global/MyKvernelandVicon.Api/';
var apiBase =
  process.env.REACT_APP_apiBase === "http://localhost:5011/"
    ? process.env.REACT_APP_apiBase
    : process.env.REACT_APP_apiBase + "/W_global/MyKvernelandVicon.Api/";
var commonBase = process.env.REACT_APP_apiBase + "/W_global/WebCommon.Api/";
var selfBase = process.env.REACT_APP_selfBase;
var runMode = process.env.REACT_APP_runMode as string;

console.log("Run mode is", runMode);

var runningUrl = selfBase;

var configUrls = {
  apiBaseUrl: apiBase + "api/",
  odataBaseUrl: apiBase + "odata/",
  commonBaseUrl: commonBase + "api/common/",
};
var configuration = {
  kvg_dev: {
    ga: {
      kvg: "UA-58484128-1",
    },
    clickDimension: {
      kvg: {
        domain: "my-kverneland-dev.kvernelandgroup.com",
        hostId: "mykvernelandcom_A1jgl2EdCymUG2gJot2Kma",
        key: "amK2toJg2GUmyCdE2lgj1A",
      },
    },
    routeBase: "/",
    defaultLanguage: "EN",
    runMode: "kvg",
    oidc: {
      enableLogging: true,
      config: {
        auth: {
          authority:
            "https://kvgb2ctest.b2clogin.com/kvgb2ctest.onmicrosoft.com/B2C_1A_V5_SignUpSignIn_Auth",
          clientId: "63236b06-fb73-4793-b542-98e5a10c218f",
          knownAuthorities: ["kvgb2ctest.b2clogin.com"],
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      },
      authenticationParameters: {
        scopes: [
          "openid",
          "profile",
          "https://kvgb2ctest.onmicrosoft.com/my-api-test/user",
        ],
      },
      redirectUrl: selfBase + "/",
      silentUrl: selfBase + "/auth.html",

      policySignIn: "B2C_1A_V5_SIGNUPSIGNIN_AUTH",
      policySignUp: "B2C_1A_V5_SignUpSignIn_Auth",
      policyEditProfile: "B2C_1A_V3_PROFILEEDIT",

      policyEditPassword: "B2C_1A_V1_PASSWORDRESET",
      policyEditEmail: "B2C_1A_V1_PROFILEEDIT_EMAIL",

      policyTerrakoEditProfile: "B2C_1A_V1_ECOMMERCE_SIGNUPCOMPLETE",

      scopesApi: ["https://kvgb2ctest.onmicrosoft.com/my-api-test/user"],
    },
    runtime: {
      urls: configUrls,
    },
  },
  kvg_test: {
    ga: {
      kvg: "UA-58484128-1",
      vicon: "UA-58480390-1",
    },
    clickDimension: {
      kvg: {
        domain: "my-test.kverneland.com",
        hostId: "mytestkvernelandcom_amrb2vDs10263gxpnkXIw",
        key: "amrb2vDs10263gxpnkXIw",
      },
      vicon: {
        domain: "my-test.vicon.eu",
        hostId: "mytestviconeu_amrb2vDs10263gxpnkXIw",
        key: "amrb2vDs10263gxpnkXIw",
      },
    },
    routeBase: "/",
    defaultLanguage: "EN",
    runMode: "kvg",
    oidc: {
      enableLogging: true,
      config: {
        auth: {
          authority:
            "https://kvgb2ctest.b2clogin.com/kvgb2ctest.onmicrosoft.com/B2C_1A_V5_SignUpSignIn_Auth",
          clientId: "63236b06-fb73-4793-b542-98e5a10c218f",
          knownAuthorities: ["kvgb2ctest.b2clogin.com"],
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      },
      authenticationParameters: {
        scopes: [
          "openid",
          "profile",
          "https://kvgb2ctest.onmicrosoft.com/my-api-test/user",
        ],
      },
      redirectUrl: selfBase + "/",
      silentUrl: selfBase + "/auth.html",
      policySignIn: "B2C_1A_V5_SignUpSignIn_Auth",
      policySignUp: "B2C_1A_V5_SignUpSignIn_Auth",
      policyEditProfile: "B2C_1A_V3_PROFILEEDIT",
      policyEditPassword: "B2C_1A_V1_PASSWORDRESET",

      policyEditEmail: "B2C_1A_V1_PROFILEEDIT_EMAIL",
      policyTerrakoEditProfile: "B2C_1A_V1_ECOMMERCE_SIGNUPCOMPLETE",
      scopesApi: ["https://kvgb2ctest.onmicrosoft.com/my-api-test/user"],
    },
    runtime: {
      urls: configUrls,
    },
  },
  kvg_prod: {
    ga: {
      kvg: "UA-58484128-1",
      vicon: "UA-58480390-1",
    },
    clickDimension: {
      kvg: {
        domain: "my.kverneland.com",
        hostId: "mykvernelandcom_A1jgl2EdCymUG2gJot2Kma",
        key: "amK2toJg2GUmyCdE2lgj1A",
      },
      vicon: {
        domain: "my.vicon.eu",
        hostId: "myviconeu_A1jgl2EdCymUG2gJot2Kma",
        key: "amK2toJg2GUmyCdE2lgj1A",
      },
    },
    routeBase: "/",
    defaultLanguage: "EN",
    runMode: "kvg",
    oidc: {
      enableLogging: false,
      config: {
        auth: {
          authority:
            "https://kvgb2cprod.b2clogin.com/kvgb2cprod.onmicrosoft.com/B2C_1A_V1_SignUpSignIn",
          clientId: "9b19137e-1f67-407e-a4b0-40426668f765",
          knownAuthorities: ["kvgb2cprod.b2clogin.com"],
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      },
      authenticationParameters: {
        scopes: [
          "openid",
          "profile",
          "https://kvgb2cprod.onmicrosoft.com/my-api/user",
        ],
      },
      redirectUrl: selfBase + "/",
      silentUrl: selfBase + "/auth.html",
      policySignIn: "B2C_1A_V1_SignUpSignIn",
      policySignUp: "B2C_1A_V1_SignUp",
      policyEditProfile: "B2C_1A_V1_ProfileEdit",
      policyEditPassword: "B2C_1A_V1_PASSWORDRESET",

      policyEditEmail: "B2C_1A_V1_PROFILEEDIT_EMAIL",
      policyTerrakoEditProfile: "B2C_1A_V1_ECOMMERCE_SIGNUPCOMPLETE",
      scopesApi: ["https://kvgb2cprod.onmicrosoft.com/my-api/user"],
    },
    runtime: {
      urls: configUrls,
    },
  },
  vicon_dev: {
    ga: {
      vicon: "UA-58480390-1",
    },
    clickDimension: {
      vicon: {
        domain: "my-vicon-dev.kvernelandgroup.com",
        hostId: "myviconeu_A1jgl2EdCymUG2gJot2Kma",
        key: "amK2toJg2GUmyCdE2lgj1A",
      },
    },
    routeBase: "/",
    defaultLanguage: "EN",
    runMode: "vicon",
    oidc: {
      enableLogging: true,
      config: {
        auth: {
          authority:
            "https://kvgb2ctest.b2clogin.com/kvgb2ctest.onmicrosoft.com/B2C_1A_V5_SignUpSignIn_Auth",
          clientId: "15c309a4-eeb6-4448-bd66-ac3703dda24c",
          knownAuthorities: ["kvgb2ctest.b2clogin.com"],
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      },
      authenticationParameters: {
        scopes: [
          "openid",
          "profile",
          "https://kvgb2ctest.onmicrosoft.com/my-api-test/user",
        ],
      },
      redirectUrl: selfBase + "/",
      silentUrl: selfBase + "/auth.html",
      policySignIn: "B2C_1A_V5_SignUpSignIn_Auth",
      policySignUp: "B2C_1A_V5_SignUpSignIn_Auth",
      policyEditProfile: "B2C_1A_V3_PROFILEEDIT",
      policyEditPassword: "B2C_1A_V1_PASSWORDRESET",

      policyEditEmail: "B2C_1A_V1_PROFILEEDIT_EMAIL",
      policyTerrakoEditProfile: "B2C_1A_V1_ECOMMERCE_SIGNUPCOMPLETE",
      scopesApi: ["https://kvgb2ctest.onmicrosoft.com/my-api-test/user"],
    },
    runtime: {
      urls: configUrls,
    },
  },
  vicon_test: {
    ga: {
      kvg: "UA-58484128-1",
      vicon: "UA-58480390-1",
    },
    clickDimension: {
      kvg: {
        domain: "my-test.kverneland.com",
        hostId: "mytestkvernelandcom_amrb2vDs10263gxpnkXIw",
        key: "amrb2vDs10263gxpnkXIw",
      },
      vicon: {
        domain: "my-test.vicon.eu",
        hostId: "mytestviconeu_amrb2vDs10263gxpnkXIw",
        key: "amrb2vDs10263gxpnkXIw",
      },
    },
    routeBase: "/",
    defaultLanguage: "EN",
    runMode: "vicon",
    oidc: {
      enableLogging: true,
      config: {
        auth: {
          authority:
            "https://kvgb2ctest.b2clogin.com/kvgb2ctest.onmicrosoft.com/B2C_1A_V5_SignUpSignIn_Auth",
          clientId: "15c309a4-eeb6-4448-bd66-ac3703dda24c",
          knownAuthorities: ["kvgb2ctest.b2clogin.com"],
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      },
      authenticationParameters: {
        scopes: [
          "openid",
          "profile",
          "https://kvgb2ctest.onmicrosoft.com/my-api-test/user",
        ],
      },
      redirectUrl: selfBase + "/",
      silentUrl: selfBase + "/auth.html",
      policySignIn: "B2C_1A_V5_SignUpSignIn_Auth",
      policySignUp: "B2C_1A_V5_SignUpSignIn_Auth",
      policyEditProfile: "B2C_1A_V3_PROFILEEDIT",
      policyEditPassword: "B2C_1A_V1_PASSWORDRESET",

      policyEditEmail: "B2C_1A_V1_PROFILEEDIT_EMAIL",
      policyTerrakoEditProfile: "B2C_1A_V1_ECOMMERCE_SIGNUPCOMPLETE",
      scopesApi: ["https://kvgb2ctest.onmicrosoft.com/my-api-test/user"],
    },
    runtime: {
      urls: configUrls,
    },
  },
  vicon_prod: {
    ga: {
      kvg: "UA-58484128-1",
      vicon: "UA-58480390-1",
    },
    clickDimension: {
      kvg: {
        domain: "my.kverneland.com",
        hostId: "mykvernelandcom_A1jgl2EdCymUG2gJot2Kma",
        key: "amK2toJg2GUmyCdE2lgj1A",
      },
      vicon: {
        domain: "my.vicon.eu",
        hostId: "myviconeu_A1jgl2EdCymUG2gJot2Kma",
        key: "amK2toJg2GUmyCdE2lgj1A",
      },
    },
    routeBase: "/",
    defaultLanguage: "EN",
    runMode: "vicon",
    oidc: {
      enableLogging: false,
      config: {
        auth: {
          authority:
            "https://kvgb2cprod.b2clogin.com/kvgb2cprod.onmicrosoft.com/B2C_1A_V1_SignUpSignIn",
          clientId: "a857b7f1-0824-4cbf-8c03-61a1d57519f4",
          knownAuthorities: ["kvgb2cprod.b2clogin.com"],
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      },
      authenticationParameters: {
        scopes: [
          "openid",
          "profile",
          "https://kvgb2cprod.onmicrosoft.com/my-api/user",
        ],
      },
      redirectUrl: selfBase + "/",
      silentUrl: selfBase + "/auth.html",
      policySignIn: "B2C_1A_V1_SignUpSignIn",
      policySignUp: "B2C_1A_V1_SignUp",
      policyEditProfile: "B2C_1A_V1_ProfileEdit",
      policyEditPassword: "B2C_1A_V1_PASSWORDRESET",

      policyEditEmail: "B2C_1A_V1_PROFILEEDIT_EMAIL",
      policyTerrakoEditProfile: "B2C_1A_V1_ECOMMERCE_SIGNUPCOMPLETE",
      scopesApi: ["https://kvgb2cprod.onmicrosoft.com/my-api/user"],
    },
    runtime: {
      urls: configUrls,
    },
  },
};

const config = configuration[runMode] as unknown as JsConfig;
export default config;

export const msalConfig = {
  auth: {
    clientId: config.oidc.config.auth.clientId,
    authority: config.oidc.config.auth.authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: config.oidc.redirectUrl,
    knownAuthorities: config.oidc.config.auth.knownAuthorities,
    navigateToLoginRequestUrl:
      config.oidc.config.auth.navigateToLoginRequestUrl,
    policySignUp: config.oidc.policySignUp,
    policyEditProfile: config.oidc.policyEditProfile,
    policyEditPassword: config.oidc.policyEditPassword,

    policyEditEmail: config.oidc.policyEditEmail,
    policyTerrakoEditProfile: config.oidc.policyTerrakoEditProfile,
  },
  cache: {
    cacheLocation: config.oidc.config.cache.cacheLocation, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: config.oidc.authenticationParameters.scopes,
};
