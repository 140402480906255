import React from 'react';
import { CmsComponent, CmsField, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import UserContext from '../../providers/user-provider';
import { Link } from 'react-router-dom';
export default class ColoredHorizontalCard extends CmsComponent {
    image: any;
    alt: any;
    header: any;
    description: any;
    cta_text: any;
    cta_link: any;
    hover_image: any;
    hover_image_alt: any;
    state = {
        user: {} as any
    }
    static contextType = UserContext;
    context!: React.ContextType<typeof UserContext>;
    constructor(props) {
        super(props);
        this.cmsFolder = "Hero";
        this.cmsZones = ["droppable", "DragDropZone"];
        this.cmsDisableDragDrop = false;

        this.image = new CmsField("Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Image : null);
        this.alt = new CmsField("Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.hover_image = new CmsField("Hover_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Hover_Image : null);
        this.hover_image_alt = new CmsField("Hover_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Alt : null);
        this.header = new CmsField("Header", CmsFieldTypes.TEXT, props && props.data ? props.data.Header : null);
        this.description = new CmsField("Description", CmsFieldTypes.TEXT, props && props.data ? props.data.Description : null);
        this.cta_text = new CmsField("CTA_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.CTA_Text : null);
        this.cta_link = new CmsField("CTA_Link", CmsFieldTypes.HREF, props && props.data ? props.data.CTA_Link : null);
    }

    componentDidMount() {
        const { user } = this.context || {};
        this.setState({ user: user })
    }
    render() {
        return (
            <React.Fragment>


                {/* cp-scaffold
                        <a href="{CTA_Link:Href}" className="colored-card-link-wrapper">
                            <div className="horizontal-card-wrapper colored-horizontal-card">
                                <div className="horizontal-card-content">
                                    <div>
                                        <img src="{Image:Src}" alt="{Alt:Text}" className='og-img' />
                                        <img src="{Hover_Image:Src}" alt="{Hover_Image_Alt:Text}" className='hover-img' />
                                    </div>
                                    <div className="horizontal-card-text">
                                        <p className="horizontal-card-text-header">{Header:Text}</p>
                                        <p className="horizontal-card-text-desc">{Description:Text}</p>
                                    </div>
                                </div>

                                <div className="horizontal-card-link">

                                    <span>{CTA_Text:Text} <span className="round-arrow" ><i className="arrow right border-white"></i></span></span>
                                </div>
                            </div>
                        </a>
                    else */}
                {this.cta_link.value.includes('https') ?
                    <a href={this.cta_link} className="colored-card-link-wrapper"
                        onClick={(e) => {

                            if (this.cta_link.value === '#b2c-edit-profile') {

                                e.preventDefault();
                                this.state.user.editProfile(window.localStorage.lang);
                            }
                            else if (this.cta_link.value === '#b2c-edit_password') {

                                e.preventDefault();
                                this.state.user.editPassword(window.localStorage.lang);
                            }
                            else if (this.cta_link.value === '#b2c-edit_email') {

                                e.preventDefault();
                                this.state.user.editEmail(window.localStorage.lang);
                            }

                        }}>
                        <div className="horizontal-card-wrapper colored-horizontal-card">
                            <div className="horizontal-card-content">
                                <div>
                                    <img src={this.image} alt={this.alt} className='og-img' />
                                    <img src={this.hover_image} alt={this.hover_image_alt} className='hover-img' />
                                </div>
                                <div className="horizontal-card-text">
                                    <p className="horizontal-card-text-header">{this.header}</p>
                                    <p className="horizontal-card-text-desc">{this.description}</p>
                                </div>
                            </div>

                            <div className="horizontal-card-link">

                                <span>{this.cta_text} <span className="round-arrow" ><i className="arrow right border-white"></i></span></span>
                            </div>
                        </div>
                    </a>
                    :
                    <Link to={this.cta_link.value} className="colored-card-link-wrapper"
                        onClick={(e) => {

                            if (this.cta_link.value === '#b2c-edit-profile') {

                                e.preventDefault();
                                this.state.user.editProfile(window.localStorage.lang);
                            }
                            else if (this.cta_link.value === '#b2c-edit_password') {

                                e.preventDefault();
                                this.state.user.editPassword(window.localStorage.lang);
                            }
                            else if (this.cta_link.value === '#b2c-edit_email') {

                                e.preventDefault();
                                this.state.user.editEmail(window.localStorage.lang);
                            }
                        }}
                    >
                        <div className="horizontal-card-wrapper colored-horizontal-card">
                            <div className="horizontal-card-content">
                                <div  className='icon-svg'>
                                    <img src={this.image} alt={this.alt} className='og-img' />
                                    <img src={this.hover_image} alt={this.hover_image_alt} className='hover-img' />
                                </div>
                                <div className="horizontal-card-text">
                                    <p className="horizontal-card-text-header">{this.header}</p>
                                    <p className="horizontal-card-text-desc">{this.description}</p>
                                </div>
                            </div>

                            <div className="horizontal-card-link">
                                <span>{this.cta_text} <span className="round-arrow" ><i className="arrow right border-white"></i></span></span>
                            </div>
                        </div>
                    </Link>}
                {/* /cp-scaffold */}
            </React.Fragment>
        )
    }
}
